import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listMyOrders } from "../../actions/orderActions";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import ProfileLayout from "../../layout/ProfileLayout";

const OrderItem = ({ order }) => {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isAndroid = /Android/.test(navigator.userAgent);

  return (
    <div className="flex items-center justify-between mb-2 w-full py-2 px-4 border rounded-md">
      <div className="">
        {/* <div className='w-20 float-left'>
          <img src={order.product?.images[0]?.image} alt="" />
        </div> */}
        <div className="pl-2 float-right">
          <div className="text-xl pb-2">
            <a
              className="text-blue-500 border-b"
              href={"/profile/mes-commandes/" + order._id}
            >
              #{order._id.substring(0, 8)}{" "}
            </a>
            ... ({order.orderItems.length} x items)
          </div>
          <div className="pb-1">
            Total :{" "}
            {order.orderItems.reduce(function (a, b) {
              return a + parseFloat(b.price) * b.qty;
            }, 0)}{" "}
            MAD
          </div>
          <div className="">
            <div>
              Etat :{" "}
              {order.isPaid ? <span className="text-green-600">Payé</span> : ""}
            </div>
          </div>
        </div>
      </div>

      {/* <a href={`https://aylink.ma/api/orders/${order?._id}/export-ticket/`}> */}
      <a
        href={
          isIOS
            ? "/link-to-ios-app"
            : isAndroid
            ? "/link-to-android-app"
            : "/#footer"
        }
      >
        <div className="flex items-center bg-primary text-white gap-x-2  rounded-full py-2 px-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z"
            />
          </svg>
          Voir Sur notre app
        </div>
      </a>
    </div>
  );
};

function MyOrderListScreen({ history }) {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const orderListMy = useSelector((state) => state.orderListMy);
  const { loading: loadingOrders, error: errorOrders, orders } = orderListMy;

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    } else {
      dispatch(listMyOrders());
    }
  }, [dispatch, history, userInfo]);

  return (
    <ProfileLayout>
      <div>
        <h2 className="pb-4 text-xl ">Mes Commandes</h2>

        {loadingOrders ? (
          <Loader />
        ) : errorOrders ? (
          <Message variant="danger">{errorOrders}</Message>
        ) : (
          <div>
            {orders.map((order) => (
              <OrderItem order={order} key={order._id} />
            ))}
          </div>
        )}
      </div>
    </ProfileLayout>
  );
}

export default MyOrderListScreen;
