import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/userActions";
import { useEffect } from "react";
import Loader from "./Loader";
import Message from "./Message";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email")
    .lowercase()
    .required("Email is required "),
  password: yup.string().required("Password is required"),
});

const Login = ({ setIsOpen, setIsLogin }) => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  useEffect(() => {
    if (userInfo) {
      setIsOpen(false);
    }
  }, [userInfo]);

  const onSubmit = (data) => {
    dispatch(login(data));
  };

  return (
    <>
      {error && <Message variant="danger">{error}</Message>}
      {loading && <Loader />}
      <form className="w-full " onSubmit={handleSubmit(onSubmit)}>
        <div className="my-3">
          <div className="opacity-80"> Adresse email</div>
          <div className="border  rounded-full ">
            <input
              className="outline-none w-full lowercase p-3 bg-transparent "
              type="email"
              placeholder="Adresse email"
              {...register("email")}
            />
          </div>
          {errors.email && (
            <p className="text-xs italic text-red-600 ">
              {errors.email.message}
            </p>
          )}
        </div>

        <div className="my-3">
          <div className="opacity-80">Mot de passe</div>
          <div className="border rounded-full my-3">
            <input
              type="password"
              className="outline-none w-full p-3 bg-transparent"
              placeholder="******"
              {...register("password")}
            />
          </div>
          {errors.password && (
            <p className="text-xs italic text-red-600 ">
              {errors.password.message}
            </p>
          )}
        </div>
        <div className="flex justify-end ">
          <a href={"/reset-password"} className="text-secondary">
            Mot de passe oublié ?
          </a>
        </div>
        <div className="pt-4 flex items-center justify-center">
          <button
            className="p-3  px-10 rounded-full text-center text-white bg-primary"
            type="submit"
          >
            Connexion
          </button>
        </div>
      </form>

      <div className="py-3 text-center">
        <div>
          Nouveau compte ?{" "}
          <button
            className="text-secondary"
            onClick={() => {
              setIsLogin(false);
            }}
          >
            S'inscrire
          </button>
        </div>
      </div>
    </>
  );
};
export default Login;
