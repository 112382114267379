import React from "react";

function SliderHome() {
  return (
    // <div
    //   style={{ backgroundImage: "url(./images/bg_cover.jpeg)" }}
    //   className="text-white header bg-cover w-full  h-[70vh]  overflow-hidden "
    // >
    //   <div className="flex max-w-screen-lg mx-auto  md:items-center pt-14 md:py-10">
    //     <div className="block w-1/2 p-5 md:p-10 pt-20 md:pl-20 ">
    //       <h1 className="font-semibold text-2xl md:text-4xl ">
    //         Achetez vos tickets{" "}
    //         <span className="text-secondary">en deux clics !</span>
    //       </h1>
    //       <p className="mt-4 md:text-lg md:block hidden  ">
    //         Retrouvez vos évenements préférés et achetez vos billets en toute
    //         sécurité.
    //       </p>
    //       <div className="mt-6 md:mt-12">
    //         <a
    //           className=" text-white font-bold md:px-6 md:py-4 px-3 py-3 rounded-full outline-none focus:outline-none mr-1 mb-1 bg-secondary uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
    //           href="#events"
    //         >
    //           Explore
    //         </a>
    //       </div>
    //     </div>
    //     <div className="relative w-1/2 md:block md:p-10  overflow-hidden ">
    //       <img className="" src="/images/image_home.png" alt="" srcSet="" />
    //     </div>
    //   </div>
    //   <div>

    //   </div>
    // </div>

    <div
      style={{ backgroundImage: "url(./images/bg_slider.jpeg)" }}
      className="text-white bg-cover w-full bg-no-repeat relative py-8 md:h-[78vh] bg-hero  "
    >
      <div className="flex p-4 max-w-screen-lg mx-auto  items-center justify-center gap-3 pt-20 md:py-10">
        <div className="block w-1/2 text-white ">
          <h1 className="font-semibold text-2xl md:text-4xl ">
            Achetez vos tickets{" "}
            <span className="text-secondary">en deux clics !</span>
          </h1>
          <p className="mt-4 md:text-lg text-sm  ">
            Retrouvez vos évenements préférés et achetez vos billets en toute
            sécurité.
          </p>
          <div className="mt-6 md:mt-12">
            <a
              className=" text-white font-bold md:px-6 md:py-4 px-3 py-3 rounded-full outline-none focus:outline-none mr-1 mb-1 bg-secondary uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
              href="#events"
            >
              Explorer
            </a>
          </div>
        </div>
        <div className="relative  w-1/2 md:pt-10  md:block  ">
          <div className="">
            <img src="/images/home_image_3.png" alt="" srcSet="" />
          </div>
        </div>
      </div>

      <div class="absolute  inset-x-0 bottom-0">
        <svg
          viewBox="0 0 224 12"
          fill="currentColor"
          class="w-full -mb-1 h-6 md:h-14 text-white"
          preserveAspectRatio="none"
        >
          <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z"></path>
        </svg>
      </div>

      {/* <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div class="relative max-w-2xl sm:mx-auto sm:max-w-xl md:max-w-2xl sm:text-center">
          <h2 class="mb-6 font-sans text-3xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none">
            The quick, brown fox
            <br class="hidden md:block" />
            jumps over a
            <span class="relative inline-block">
              lazy dog
              <div class="w-full h-3 -mt-3 bg-deep-purple-accent-400"></div>
            </span>
          </h2>
          <p class="mb-6 text-base font-thin tracking-wide text-gray-300 md:text-lg">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            quae. explicabo. Sed ut perspiciatis unde omnis.
          </p>
          <form class="flex flex-col items-center w-full mb-4 md:flex-row md:px-16">
            <input
              placeholder="Email"
              required=""
              type="text"
              class="flex-grow w-full h-12 px-4 mb-3 text-white transition duration-200 bg-transparent border-2 border-gray-400 rounded appearance-none md:mr-2 md:mb-0 focus:border-deep-purple-accent-200 focus:outline-none focus:shadow-outline"
            />
            <button
              type="submit"
              class="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
            >
              Subscribe
            </button>
          </form>
          <p class="max-w-md mb-10 text-xs font-thin tracking-wide text-gray-500 sm:text-sm sm:mx-auto md:mb-16">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium.
          </p>
          <a
            href="/"
            aria-label="Scroll down"
            class="flex items-center justify-center w-10 h-10 mx-auto text-white duration-300 transform border border-gray-400 rounded-full hover:text-teal-accent-400 hover:border-teal-accent-400 hover:shadow hover:scale-110"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="currentColor"
            >
              <path d="M10.293,3.293,6,7.586,1.707,3.293A1,1,0,0,0,.293,4.707l5,5a1,1,0,0,0,1.414,0l5-5a1,1,0,1,0-1.414-1.414Z"></path>
            </svg>
          </a>
        </div>
      </div> */}
    </div>
  );
}

export default SliderHome;
