import React from "react";
import generatedHash from "../utils/generateHash";

function PaymentForm({ order, title, className, disabled }) {
  const _url = `https://payment.aylink.ma/?order=${order._id}&from=${order.from}`;

  const dataForm = {
    // clientid: "600003362",
    clientid: "600003298",
    storetype: "3d_pay_hosting",
    okUrl: `https://payment.aylink.ma/okfail.php`,
    rnd: "453",
    hashAlgorithm: "ver3",
    encoding: "UTF-8",
    TranType: "PreAuth",
    currency: "504",
    amount: order.totalPrice,
    CallbackResponse: "true",
    BillToCity: "",
    email: order?.user?.email,
    tel: order?.user?.phone,
    BillToStateProv: "",
    BillToCountry: "",
    BillToStreet1: "",
    BillToTelVoice: "",
    shopurl: `${_url}`,
    failUrl: `https://payment.aylink.ma/okfail.php`,
    callbackUrl: `https://payment.aylink.ma/callback.php`,
    BillToName: `${order?.user?.first_name} ${order?.user?.last_name}`,
    lang: "fr",
    // oid:new Date().getTime(),
    // produ
    oid: order._id,
  };

  return (
    <form method="POST" action="https://payment.cmi.co.ma/fim/est3Dgate">
      {/* <form onSubmit={prePayment}> */}
      {Object.keys(dataForm).map((key) => {
        return (
          <div key={key}>
            <input type="hidden" name={key} value={dataForm[key]} />
          </div>
          // <li key={key + index}>{key}:{items[key]}</li>
        );
      })}

      <input type="hidden" name="hash" value={generatedHash(dataForm)} />
      <button className={className} disabled={disabled}>
        {title}
      </button>
    </form>
  );
}

export default PaymentForm;
