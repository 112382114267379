import React, { useEffect, useState } from "react";
import PaymentForm from "../components/PaymentForm";
import { useLocation } from "react-router-dom";
import axiosInstance from "../axios";

function DonateConfirmScreen() {
  const location = useLocation();
  const [donation, setDonation] = useState({});
  const id = location.search.split("=")[1];

  useEffect(() => {
    axiosInstance
      .get("/donation/" + id)
      .then((res) => setDonation(res.data))
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  return (
    <div className="pt-32 min-h-screen  relative">
      <div className="max-w-screen-sm mx-auto p-4">
        <h1 className="font-bold text-xl ">AYLINK Donation</h1>
        <img
          src="https://epiceventsstatic.s3.amazonaws.com/images/Organizer/avatar/Profil.png"
          className="w-20 h-20 mx-auto rounded-full"
          alt=""
        />
        <h1 className="text-center pt-2 font-semibold">BMS Gala Dinner</h1>
        {/* details about the order - 2 */}
        <div className="py-3">
          <div>
            <span>Full Name :</span> <span>{donation.fullName}</span>
          </div>
          <div>
            <span>Email :</span> <span>{donation.emailDon}</span>
          </div>
          <div>
            <span>Phone :</span> <span>{donation.phoneDon}</span>
          </div>
          <div>
            <span>Amount :</span> <span>{donation.totalDon}</span>
          </div>
        </div>
        <PaymentForm
          //   user={userInfo}
          //   disabled={isNotValid}
          className={
            "hover:scale-[1.01] disabled:scale-[1] disabled:bg-opacity-70 hover:bg-opacity-90 w-full rounded-full p-3 bg-primary text-white"
          }
          title={"Pay Now"}
          order={{
            _id: donation._id,
            user: {
              first_name: donation.fullName,
              last_name: "-",
              email: donation.emailDon,
              phone: donation.phoneDon,
            },
            totalPrice: donation.totalDon,
            from: "don",
          }}
        />
      </div>
    </div>
  );
}

export default DonateConfirmScreen;
