import { SECTION_LIST_FAIL, SECTION_LIST_REQUEST, SECTION_LIST_SUCCESS } from "../constants/sectionConstants"




export const sectionListReducer = (state = { sections: []}, action) => {
    switch (action.type) {
        case SECTION_LIST_REQUEST:
            return { loading: true ,sections: []}
        case SECTION_LIST_SUCCESS:
            return { loading: false, sections: action.payload.sections }
        case SECTION_LIST_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}
  
  