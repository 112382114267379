import React from "react";
import { useLocation } from "react-router-dom";

const SideBar = () => {
  const { pathname } = useLocation();
  return (
    <div className="p-4 shadow-md w-full md:w-auto rounded-md border">
      <ul className="whitespace-nowrap">
        <li>
          <a
            className={pathname.includes("info") ? "text-secondary" : ""}
            href="/profile/info"
          >
            Mon profil
          </a>
        </li>
        <li>
          <a
            className={pathname.includes("commandes") ? "text-secondary" : ""}
            href="/profile/mes-commandes"
          >
            Mes commandes
          </a>
        </li>
        <li>
          <a
            className={pathname.includes("passe") ? "text-secondary" : ""}
            href="/profile/changer-mot-de-passe"
          >
            Changer le mot de passe
          </a>
        </li>
      </ul>
    </div>
  );
};

function ProfileLayout(props) {
  return (
    <div className="max-w-screen-lg p-4 min-h-screen items-start mx-auto flex md:flex-row flex-col  gap-4 pt-24">
      <SideBar />

      <div className="flex-shrink w-full p-4 border shadow-md rounded-md">
        {props.children}
      </div>
    </div>
  );
}

export default ProfileLayout;
