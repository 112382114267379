import React from 'react'
import { Link } from 'react-router-dom'

const CheckoutSteps = ({ step1, step2, step3, step4 }) => {
  return (
    
    <div className='flex  justify-center py-4 bg-white '>
      <div >
        {step1 ? (
          <div className="" >
            <Link className="border-b-2 px-4 pb-2  border-black" to='/login'>Sign In</Link>
          </div>
        ) : (
          <Link className="px-4">Sign In</Link>
        )}
      </div>

      <div >
        {step2 ? (
          <div className='' >
            <Link className="px-4 border-b-2 pb-2 border-black" to='/shipping'>Information</Link>
          </div>
        ) : (
          <Link className="px-4 text-gray-400">Information</Link>
        )}
      </div>
      <div >
        {step4 ? (
          <div >
            <Link className="px-4 border-b-2 pb-2  border-black" to='/order' > Preview</Link>
          </div>
        ) : (
          <Link className="px-4 text-gray-400">Preview </Link>
        )}
      </div>
{/* 
      <div className="">
        { step3 ? (
          <div >
            <Link className="px-4 border-b-2 pb-2  border-black" to='/payment'>Payment</Link>
          </div>
        ) : (
          <Link className="px-4 pb-2 text-gray-400">Payment</Link>
        )}
      </div> */}

   
    </div>
  )
}

export default CheckoutSteps
