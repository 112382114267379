import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserProfileDetails,
  updateUserPassword,
} from "../../actions/userActions";
import Loader from "../../components/Loader";
import ProfileLayout from "../../layout/ProfileLayout";

function ChangePasswordScreen({ history }) {
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const { loading, error } = useSelector((state) => state.userPasswordUpdate);
  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const handleChangePassword = (e) => {
    e.preventDefault();
    if (password !== "" && password !== confirmPassword) {
      setErrorMessage("Passwords do not match !");
    } else {
      dispatch(updateUserPassword(user.id, oldPassword, password));
    }
  };

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    } else {
      dispatch(getUserProfileDetails());
    }
  }, [dispatch, userInfo]);

  return (
    <ProfileLayout>
      <h2 className="pb-4 text-xl ">Changer le mot passe</h2>
      <div className=" text-red-600">
        {errorMessage && errorMessage}
        {loading ? <Loader /> : error ? error : ""}
      </div>
      <form onSubmit={handleChangePassword} method="post">
        <div className="mb-3">
          <label
            for="old_password"
            class="block mb-2 text-sm font-medium text-gray-900 "
          >
            Ancien mot de passe
          </label>
          <input
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            id="old_password"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            placeholder="*******"
            required
          />
        </div>
        <div className="mb-3">
          <label
            for="new_password"
            class="block mb-2 text-sm font-medium text-gray-900 "
          >
            Nouveau mot de passe
          </label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            id="new_password"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            placeholder="******"
            required
          />
        </div>

        <div className="mb-3">
          <label
            for="confirm_new_password"
            class="block mb-2 text-sm font-medium text-gray-900 "
          >
            Confirmer le nouveau mot de passe
          </label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            id="confirm_new_password"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            placeholder="******"
            required
          />
        </div>

        <div className="mt-2 flex justify-end w-full">
          <button
            type="submit"
            className="bg-primary px-3 rounded-full text-white p-2 "
          >
            Enregistrer
          </button>
        </div>
      </form>
    </ProfileLayout>
  );
}

export default ChangePasswordScreen;
