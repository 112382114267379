import React from "react";
import { Link } from "react-router-dom";
import getFormatedPrice from "../utils/getFormatedPrice";
import { baseURL, baseURLFiles } from "../axios";

const Product = ({ product, organizer }) => {
  return (
    <div className="relative ">
      <Link to={`/p/${organizer}/${product.slug}`}>
        <div className="w-full h-72 fade-in lg:group-hover:scale-105 group-hover:shadow-me duration-300 rounded-xl  overflow-hidden  shadow-me">
          <img
            src={
              product &&
              product.images &&
              (baseURL === "/api/"
                ? product.images[0]?.image
                : baseURLFiles + product.images[0]?.image)
            }
            alt={product.image}
            className=" duration-300 w-full h-full object-center object-cover  "
          />
        </div>
      </Link>
      <div className="py-2">
        <div className="text-center ">
          <p className="opacity-70 uppercase font-mono tracking-widest text-sm md:text-base ">
            {product.name}
          </p>
          <h3 className="font-600 pb-2 mt-1 text-base md:text-xl tracking-wider  uppercase">{`${getFormatedPrice(
            product.price
          )}`}</h3>
        </div>
      </div>
      {product.countInStock === 0 ? (
        <div className="bg-red-600 bg-opacity-80 absolute top-5 right-5 p-2 text-white rounded-md">
          Sold Out
        </div>
      ) : (
        <div></div>
      )}
      {/* </div> */}
    </div>
  );
};

export default Product;
