
export const SECTION_CREATE_REQUEST = 'SECTION_CREATE_REQUEST'
export const SECTION_CREATE_SUCCESS = 'SECTION_CREATE_SUCCESS'
export const SECTION_CREATE_FAIL = 'SECTION_CREATE_FAIL'
export const SECTION_CREATE_RESET = 'SECTION_CREATE_RESET'

export const SECTION_LIST_REQUEST = 'SECTION_LIST_REQUEST'
export const SECTION_LIST_SUCCESS = 'SECTION_LIST_SUCCESS'
export const SECTION_LIST_FAIL = 'SECTION_LIST_FAIL'

export const SECTION_DELETE_REQUEST = 'SECTION_DELETE_REQUEST'
export const SECTION_DELETE_SUCCESS = 'SECTION_DELETE_SUCCESS'
export const SECTION_DELETE_FAIL = 'SECTION_DELETE_FAIL'

export const SECTION_UPDATE_REQUEST = 'SECTION_UPDATE_REQUEST'
export const SECTION_UPDATE_SUCCESS = 'SECTION_UPDATE_SUCCESS'
export const SECTION_UPDATE_FAIL = 'SECTION_UPDATE_FAIL'

