import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/ProductScreen";
import CartScreen from "./screens/CartScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";

import ProductsListScreen from "./screens/ProductsListScreen";
import SucessPayment from "./screens/SucessPayment";
import FailPayment from "./screens/FailPayment";
import Cgv from "./screens/Cgv";
import OrganizationScreen from "./screens/OrganizationScreen";
import MyProfileScreen from "./screens/profile/MyProfileScreen";
import MyOrderListScreen from "./screens/profile/MyOrderListScreen";
import ChangePasswordScreen from "./screens/profile/ChangePasswordScreen";
import ContactUs from "./screens/ContactUs";
import MyOrderDetailScreen from "./screens/profile/MyOrderDetailScreen";
import ResetPassword from "./screens/ResetPassword";
import ResetPasswordConfirm from "./screens/ResetPasswordConfirm";
import DonateScreen from "./screens/DonateScreen";
import DonateConfirmScreen from "./screens/DonateConfirmScreen";
import MentionsLegal from "./pages/MentionsLegal";
import AboutUs from "./pages/AboutUs";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return null;
}

const App = () => {
  return (
    <div className="">
      <Router>
        <ScrollToTop />
        <Header />
        <main className="min-h-screen pb-20">
          <>
            <Route
              path="/p/:organization/:slug"
              exact
              component={ProductScreen}
            />

            <Switch>
              <Route path={"/reset-password"} exact component={ResetPassword} />
              <Route path="/payment/ok" component={SucessPayment} />
              <Route path="/payment/fail" component={FailPayment} />
              <Route
                path={"/reset-password-confirm"}
                exact
                component={ResetPasswordConfirm}
              />

              <Route path="/donate/confirm" component={DonateConfirmScreen} />
              <Route path="/donate" component={DonateScreen} />
              <Route path="/contact-us" component={ContactUs} exact></Route>

              <Route path="/terms-and-conditions" component={Cgv} />
              <Route path="/pages/mentions-legales" component={MentionsLegal} />
              <Route path="/pages/contacter-nous" component={ContactUs} />
              <Route path="/pages/qui-sommes-nous" component={AboutUs} />

              <Route path="/placeorder" component={PlaceOrderScreen} />
              <Route path="/profile/info" component={MyProfileScreen} />
              <Route
                path="/profile/mes-commandes"
                exact
                component={MyOrderListScreen}
              />
              <Route
                path="/profile/mes-commandes/:id"
                component={MyOrderDetailScreen}
              />

              <Route
                path="/profile/changer-mot-de-passe"
                component={ChangePasswordScreen}
              />
              <Route path="/order/:id" component={OrderScreen} />
              <Route path="/cart/:id?" component={CartScreen} />
              <Route
                path="/tickets/:category/:sub/"
                component={ProductsListScreen}
                exact
              />

              <Route
                path="/category/:category"
                component={ProductsListScreen}
                exact
              />
              <Route
                path="/:organization"
                component={OrganizationScreen}
                exact
              />
              <Route
                path="/:organization/:pageNumber"
                component={OrganizationScreen}
                exact
              />
            </Switch>

            <Route path="/" component={HomeScreen} exact></Route>
            {/* <Route path='*' component={NotFound} ></Route> */}

            {/* <Route path='/:pageNumber' component={ProductsListScreen} exact /> */}
          </>
        </main>

        <Footer />
      </Router>
    </div>
  );
};

export default App;
