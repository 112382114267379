import {
  EXPIRED_ORGANIZER_LIST_FAIL,
  EXPIRED_ORGANIZER_LIST_REQUEST,
  EXPIRED_ORGANIZER_LIST_SUCCESS,
  ORGANIZER_DETAILS_FAIL,
  ORGANIZER_DETAILS_REQUEST,
  ORGANIZER_DETAILS_SUCCESS,
  ORGANIZER_LIST_FAIL,
  ORGANIZER_LIST_REQUEST,
  ORGANIZER_LIST_SUCCESS,
} from "../constants/organizerConstants";

export const organizerListReducer = (state = { organizers: [] }, action) => {
  switch (action.type) {
    case ORGANIZER_LIST_REQUEST:
      return { loading: true, organizers: [] };
    case ORGANIZER_LIST_SUCCESS:
      return {
        loading: false,
        organizers: action.payload.organizers,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case ORGANIZER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const expiredOrganizerListReducer = (
  state = { organizers: [] },
  action
) => {
  switch (action.type) {
    case EXPIRED_ORGANIZER_LIST_REQUEST:
      return { loading: true, organizers: [] };
    case EXPIRED_ORGANIZER_LIST_SUCCESS:
      return {
        loading: false,
        organizers: action.payload.organizers,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case EXPIRED_ORGANIZER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const organizerDetailReducer = (state = { organizer: {} }, action) => {
  switch (action.type) {
    case ORGANIZER_DETAILS_REQUEST:
      return { loading: true, organizer: {} };

    case ORGANIZER_DETAILS_SUCCESS:
      return {
        loading: false,
        organizer: action.payload,
      };
    case ORGANIZER_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
