import React, { useState } from "react";
import Dialog from "./Dialog";
import Login from "./Login";
import Register from "./Register";

function LoginOrRegister({ isOpen, setIsOpen }) {
  const [isLogin, setIsLogin] = useState(true);

  // const [isLogin, setIsLogin] = useState(true); // true for login view, false for register view

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog isShow={isOpen} setIsShow={setIsOpen}>
      <div className="max-w-screen-sm min-w-[360px] md:p-4">
        {isLogin ? (
          <Login setIsLogin={setIsLogin} setIsOpen={setIsOpen} />
        ) : (
          <Register setIsLogin={setIsLogin} setIsOpen={setIsOpen} />
        )}
      </div>
    </Dialog>
  );
}

export default LoginOrRegister;
