import React from "react";

function Dialog({ children, isShow, setIsShow }) {
  return (
    <div className="">
      {isShow && (
        <>
          <div
            onClick={() => setIsShow(false)}
            className="fixed h-screen w-screen top-0 left-0 right-0 bottom-0 bg-gray-500 bg-opacity-60 backdrop-blur-sm z-40 "
          ></div>
          <div
            className={`fixed top-1/2 left-1/2  -translate-y-1/2 -translate-x-1/2 z-50 bg-white border shadow-sm p-4 rounded-md`}
          >
            {children}
          </div>
        </>
      )}
    </div>
  );
}

export default Dialog;
