import React from "react";

function Cgv() {
  return (
    <div className="max-w-screen-md mx-auto pt-32  px-2">
      <h1 className="font-bold underline text-center md:text-3xl text-2xl ">
        Conditions Générales de Vente
      </h1>
      {/*  */}
      <div className="mt-8">
        <p className="mb-2 md:text-base text-sm">
          <b>Aylink</b> est une plateforme de billetterie de nouvelle génération
          développée pour faciliter et sécuriser tous vos achats et ventes de
          billets, tant pour les organisateurs d'événements que pour les
          participants. Servant d'intermédiaire entre les organisateurs
          d'événements et les clients achetant des billets, nous aidons à
          simplifier au mieux le processus technologique de la transaction, sans
          avoir de responsabilité ni de décision concernant les prix, le type de
          billet et toutes les informations téléchargées par l'organisateur de
          l'événement. Nous vous offrons une technologie de pointe et de
          nouvelles fonctionnalités haut de gamme, faisant de nous une véritable
          plateforme de billetterie de nouvelle génération leader.
        </p>
      </div>
      <div className="mt-5 mb-2">
        <h1 className="font-bold md:text-2xl text-xl">
          1 - Dispositions générales
        </h1>
      </div>
      {/*  */}
      <div>
        <h2 className="font-bold mb-2 md:text-xl ">Société:</h2>
        <p className="mb-2 md:text-base text-sm">
          <b>Aylink</b> est une marque développée et possédée par AYNNAKA SARL
          immatriculé sous le numéro: 002550006000050, inscrite au Registre de
          commerce sous le numéro 105243 dont le siège social est situé à No
          166, Avenue Abdelkrim Khattabi, Gueliz 40000 Marrakech
        </p>
      </div>
      {/*  */}
      <div>
        <h2 className="font-bold mb-2  md:text-xl">Acceptation:</h2>
        <p className="mb-2 md:text-base text-sm">
          Le Membre déclare avoir pris connaissance des Conditions Générales de
          Vente avant de passer commande et les avoir acceptées sans réserve.
        </p>
      </div>
      {/*  */}
      <div>
        <h2 className="font-bold mb-2 md:text-xl ">Objet:</h2>
        <p className="mb-2 md:text-base text-sm">Dispositions contractuelles</p>
      </div>
      {/*  */}
      <div>
        <h2 className="font-bold mb-2  md:text-xl">
          Dispositions contractuelles:
        </h2>
        <p className="mb-2 md:text-base text-sm">
          La nullité d’une clause contractuelle des Conditions Générales de
          Vente n’entraînera pas la nullité de l’intégralité des présentes.
        </p>
      </div>
      {/*  */}
      <div>
        <h2 className="font-bold mb-2  md:text-xl">
          Modification des conditions générales de vente:
        </h2>
        <p className="mb-2 md:text-base text-sm">
          <b>Aylink</b> se réserve la faculté de modifier les présentes
          Conditions Générales de Vente à tout moment. Les Conditions Générales
          de Vente applicables sont celles en vigueur à la date de la commande
          passée par le Membre.
        </p>
      </div>
      {/*  */}
      <div className="mt-5 mb-2">
        <h1 className="font-bold md:text-2xl text-xl">
          2. Conditions générales de vente
        </h1>
      </div>
      <div>
        <p className="mb-2 md:text-base text-sm">
          Les présentes conditions Générales de Vente (CGV) sont applicables à
          l’ensemble des Articles proposés à la vente sur l'application{" "}
          <b>Aylink</b>
        </p>
        <p className="mb-2 md:text-base text-sm">
          Toute Commande sur l'application ou sur notre site-web est
          conditionnée par la consultation et l’acceptation préalables et sans
          réserve de la part du Client des présentes CGV et des tarifs
          applicables.
        </p>
        <p className="mb-2 md:text-base text-sm">
          En passant une commande sur notre application ou sur notre site-web,
          quel que soit le moyen utilisé, vous acceptez de vous soumettre à ces
          Conditions Générales de Vente.
        </p>
        <p className="mb-2 md:text-base text-sm">
          Les commandes passées sur le site Web constituent un engagement ferme
          et définitif. Après confirmation de la commande par <b>Aylink</b> par
          courrier électronique à l'adresse e-mail fournie par le membre,
          celle-ci est définitive.
        </p>
        <p className="mb-2 md:text-base text-sm">
          Étant donné que les commandes sont définitives et irrévocables, toute
          demande de modification émise par le Membre est sujette à
          l'approbation de l’administration <b>Aylink</b>.
        </p>
      </div>
      {/*  */}
      <div className="mt-5 mb-2">
        <h1 className="font-bold md:text-2xl text-xl">3. Prix et Paiements</h1>
      </div>
      <div>
        <p className="mb-2 md:text-base text-sm">
          Les tarifs de nos produits sont affichés en Dirham marocain, toutes
          taxes comprises (TVA et autres taxes applicables au jour de la
          commande), sauf mention contraire.
        </p>
        <p className="mb-2 md:text-base text-sm">
          <b>Aylink</b> et ses partenaires se réservent le droit de modifier
          leurs prix à tout moment, ainsi que le droit de modifier les produits
          ou services disponibles à la vente sur le site web et l'application
          mobile.
        </p>
        <p className="mb-2 md:text-base text-sm">
          L'événement relève de la responsabilité exclusive du producteur,
          notamment en ce qui concerne le respect de la réglementation et des
          lois en vigueur, la sécurité des clients, la politique d'accueil et la
          procédure de remboursement en cas d'annulation. En tant
          qu'intermédiaire de vente, <b>Aylink</b> ne saurait être tenu
          responsable de l'une de ces situations.
        </p>
        <p className="mb-2 md:text-base text-sm">
          Un billet de spectacle ne peut être remboursé même en cas de perte ou
          de vol, ni repris, ni échangé sauf en cas d'annulation d'un spectacle.
        </p>
        <p className="mb-2 md:text-base text-sm">
          En cas d'annulation, c'est à l'organisateur de décider du moment, du
          montant et de la communication concernant le remboursement auprès des
          clients. En tant que frais de service, <b>Aylink</b> retiendra auprès
          de l'organisateur les commissions initialement perçues au moment de
          l'achat du billet par le client.
        </p>
        <p className="mb-2 md:text-base text-sm">
          Le paiement se fait en ligne via l'application mobile AYLINK ou bien
          via notre site-web <a href="https://aylink.ma/">www.aylink.ma</a>.
        </p>
      </div>
      {/*  */}
      <div className="mt-5 mb-2">
        <h1 className="font-bold md:text-2xl text-xl">4. Achats</h1>
      </div>
      <div>
        <p className="mb-2 md:text-base text-sm">
          Lorsque vous passez un achat sur l'application Mobile, nous pouvons
          vous demander de fournir des informations personnelles (nom, un numéro
          de téléphone, adresse mail de livraison de facture)
        </p>
        <p className="mb-2 md:text-base text-sm">
          Assurez-vous que vos données fournies sont exactes et complètes lors
          de l'achat d'un billet via notre site web ou notre application mobile
          afin de recevoir votre billet. <b>Aylink</b> ne peut être tenu
          responsable si vous transférez votre billet à la mauvaise adresse ou
          partagez votre mot de passe.
        </p>
        <p className="mb-2 md:text-base text-sm">
          Afin de garantir la sécurité des billets et de contribuer à lutter
          contre les fraudes, <b>Aylink</b> propose une nouvelle fonctionnalité
          appelée « transfert ». Vous pouvez désormais envoyer votre billet à un
          ami via notre application. Pour transférer votre billet, vous
          fournissez l'e-mail de votre ami qui possède déjà un compte sur{" "}
          <b>Aylink</b>, et une fois que vous appuyez sur "confirmer", le billet
          est automatiquement transféré de votre application mobile vers la
          leur. Assurez-vous de fournir le bon e-mail avant de confirmer, car
          une fois que vous appuyez sur "confirmer", le billet est
          automatiquement transféré et
          <b>Aylink</b> ne peut être tenu responsable.
        </p>
        <p className="mb-2 md:text-base text-sm">
          Afin de garantir la sécurité des billets et de contribuer à lutter
          contre les fraudes, <b>Aylink</b> propose une nouvelle fonctionnalité
          qui ne montre le code QR que la veille de l'événement. Vous recevez
          votre billet sur votre application mobile avec toutes les informations
          nécessaires, mais le code QR ne devient visible que 24 heures avant
          l'événement.
        </p>
        <p className="mb-2 md:text-base text-sm">
          Une fois qu'une commande est validée avec succès, vous recevrez un
          e-mail à l'adresse e-mail fournie vous demandant de télécharger
          l'application. Afin de garantir la sécurité des billets et de lutter
          contre les fraudes, le billet acheté sera accessible via notre
          application mobile. Il vous suffit de vous connecter avec les
          informations utilisées pour acheter votre billet et vous le trouverez
          dans la section "Mes Billets". 24 heures avant l'événement, vous
          pourrez télécharger le billet si vous ne souhaitez pas apporter votre
          téléphone
        </p>
        <p className="mb-2 md:text-base text-sm">
          Le paiement en ligne sur notre site est sécurisé grâce à la plateforme
          de paiement en ligne CMI. Le système de sécurisation des paiements
          électroniques mis en place par CMI est certifié par les banques
          marocaines ainsi que par les organismes internationaux VISA /
          MASTERCARD. La plate-forme CMI assure le traitement confidentiel de
          vos données bancaires et personnelles, conforme aux normes de sécurité
          en vigueur et à la loi 09-08 relative à la protection des données à
          caractère personnel.
        </p>
      </div>
      {/*  */}
      <div className="mt-5 mb-2">
        <h1 className="font-bold md:text-2xl text-xl">5. Remboursements</h1>
      </div>
      <div>
        <p className="mb-2 md:text-base text-sm">
          Un billet de spectacle ne peut être remboursé même en cas de perte ou
          de vol, ni repris, ni échangé sauf en cas d'annulation d'un spectacle.
          En cas d’annulation du spectacle, c’est a le producter (organisateur
          de l'événement) de décider du moment, du montant et de la
          communication concernant le remboursement auprès des clients. En tant
          que frais de service, <b>Aylink</b> retiendra auprès de l'organisateur
          les commissions initialement perçues au moment de l'achat du billet
          par le client.
        </p>
        <p className="mb-2 md:text-base text-sm">
          Aucun duplicata du billet ne pourra être délivré y compris en cas de
          perte ou de vol.
        </p>
        <p className="mb-2 md:text-base text-sm">
          Lors du contrôle à l'entrée du lieu de l'événement, une pièce
          d'identité, avec photo, en cours de validité pourra vous être demandée
          et elle devra correspondre au nom inscrit sur le billet.
        </p>
        <p className="mb-2 md:text-base text-sm">
          Tous les frais associés à l'achat de billets, y compris mais sans s'y
          limiter, les frais de traitement, les frais de service, les frais de
          réservation, et autres frais connexes, sont non remboursables. En cas
          d'annulation de la commande de billets ou de tout changement apporté à
          la réservation, les frais précités ne seront pas remboursés, que
          l'annulation ou la modification soit effectuée par le client ou par
          l'organisateur de l'événement. Les frais d'achat de billets sont
          considérés comme des coûts engagés pour le service de réservation et
          ne sont pas sujets à remboursement, que la réservation soit utilisée
          ou non.
        </p>
        <p className="mb-2 md:text-base text-sm">
          À l'annonce de l'annulation ou d'une modification de date, d'horaire
          ou de lieu d'un spectacle pour lequel vous avez réservé des places,
          vous acceptez que AYLINK,dans la mesure du possible, lorsqu'elle aura
          elle-même été prévenue par l'organisateur, puisse utiliser les
          coordonnées que vous avez saisies lors de la réservation pour vous
          tenir informés de la marche à suivre.
        </p>
        <p className="mb-2 md:text-base text-sm">
          Nous vous invitons en tout état de cause à vérifier 24 heures avant
          votre spectacle que celui-ci est bien maintenu sans modification.
        </p>
      </div>
      {/*  */}
      <div className="mt-5 mb-2">
        <h1 className="font-bold md:text-2xl text-xl">
          6. Suppression de compte
        </h1>
      </div>
      <div>
        <p className="mb-2 md:text-base text-sm">
          Vous pouvez supprimer votre compte à tout moment en accédant aux
          paramètres et en sélectionnant "supprimer le compte", ou vous pouvez
          nous envoyer un e-mail à{" "}
          <a href="mail:info@aylink.ma" className="font-bold">
            info@aylink.ma
          </a>{" "}
          avec vos demandes et nous procéderons à la suppression de votre
          compte.
        </p>
      </div>

      <div className="h-24"></div>

      {/*  */}
    </div>
    // <div className="pt-32 p-4 max-w-screen-md mx-auto">
    //   <h1 className="text-xl py-4">Booking Procedure</h1>
    //   <ul>
    //     <li>Choose your suitable category; FESTIVAL tickets</li>
    //     <li>Once you chose your ticket, click “add to cart”</li>
    //     <li>Click on “checkout”</li>
    //     <li>Login or Create an account.</li>
    //     <li>Proceed to payment.</li>
    //     <li>
    //       You will be directed to a secured payment platform “Visa Verified”.
    //     </li>
    //     <li>Fill in your card details and click on Pay.</li>
    //     <li>Once payment confirmed, click on “Go back to merchant site”</li>
    //     <li>
    //       You will receive a confirmation e-mail with your booking details.
    //     </li>
    //   </ul>

    //   <h1 className="text-xl py-4">Booking Conditions</h1>
    //   <ul>
    //     <li>
    //       To Confirm your booking, a 100% deposit of your total purchase is
    //       required.
    //     </li>

    //     <li>All passes are nonrefundable</li>
    //   </ul>
    // </div>
  );
}

export default Cgv;
