import React, { useEffect, useState } from "react";
import axiosInstance from "../axios";

function Categories() {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    axiosInstance
      .get(`/categories`)
      .then((res) => {
        setCategories(res.data.categories);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="py-4 max-w-screen-lg overflow-x-scroll   mx-auto">
      <ul className="flex overflow-x-scroll hide-scrollbar  md:text-xl items-center md:gap-3">
        {categories.map((cat) => (
          <li>
            <a className="hover:text-secondary whitespace-nowrap">{cat.name}</a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Categories;
