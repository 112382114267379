// import axios from "axios";
import axios from "../axios";
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_SAVE_PAYMENT_METHOD,
} from "../constants/cartConstants";

// export const addToCart =
//   (id, qty, variationId, comment = "") =>
//   async (dispatch, getState) => {
//     const { data } = await axios.get(`/api/products/${id}`);

//     const t = data.variation.attributes?.find(
//       (item) => item.id === parseInt(variationId)
//     );

//     dispatch({
//       type: CART_ADD_ITEM,
//       payload: {
//         product: data._id,
//         name: data.name,
//         description: data.description,
//         image: data.images[0].image,
//         variation_attribute_id: variationId,
//         variation_name: data?.variation?.name,
//         variation_value: t ? t.name : "",
//         comment: comment,
//         price: t ? t.price : data.price,
//         organizer: data.organizer,
//         countInStock: data.countInStock,
//         qty,
//       },
//     });

//     localStorage.setItem(
//       "cartItems",
//       JSON.stringify(getState().cart.cartItems)
//     );
//   };

export const addToCart =
  (id, qty, variationId, customization = "", customizationTitle) =>
  async (dispatch, getState) => {
    const { data } = await axios.get(`/products/${id}`);
    console.log(data);
    const t = data.variation.attributes?.find(
      (item) => item.id === parseInt(variationId)
    );
    dispatch({
      type: CART_ADD_ITEM,
      payload: {
        product: data._id,
        name: data.name,
        description: data.description,
        image: data.images[0].image,
        variation_name: data?.variation?.name,
        variation_attribute_id: t ? t.id : null,
        variation_value: t ? t.name : "",
        customization,
        customizationTitle,
        price: t ? t.price : data.price,
        organizer: data.organizer,
        countInStock: data.countInStock,
        qty,
      },
    });

    localStorage.setItem(
      "ay_cartItems",
      JSON.stringify(getState().cart.cartItems)
    );
  };

export const removeFromCart = (id) => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  });

  localStorage.setItem(
    "ay_cartItems",
    JSON.stringify(getState().cart.cartItems)
  );
};

export const saveShippingAddress = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  });
  localStorage.setItem("shippingAddress", JSON.stringify(data));
};

export const savePaymentMethod = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_PAYMENT_METHOD,
    payload: data,
  });

  localStorage.setItem("paymentMethod", JSON.stringify(data));
};
