import React, { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/userActions";
import { useState } from "react";
import useOnClickOutside from "../hooks/useOnClickOutside";
import LoginOrRegister from "./LoginOrRegister";

const Logo = ({}) => {
  return (
    <Link to={"/"}>
      <img className="w-32 md:w-44" src="/logo.png" alt="" srcSet="/logo.png" />
    </Link>
  );
};

function Header() {
  const dispatch = useDispatch();
  const [isToggled, setIsToggled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { cartItems } = useSelector((state) => state.cart);

  const { pathname } = useLocation();

  const hideHeader =
    pathname.includes("admin") ||
    pathname.includes("login") ||
    pathname.includes("register");

  const isHome = pathname === "/";
  const logoutHandler = () => {
    dispatch(logout());
  };

  const toggleMenu = () => {
    setIsToggled(!isToggled);
  };

  // const handleScroll = () => {
  //     if (window.pageYOffset > 40) {
  //        setIsHeaderSticky(true)
  //     }else{
  //        setIsHeaderSticky(false)
  //     }
  // }

  // useEffect(() => {

  //     window.addEventListener("scroll", handleScroll);

  //     return () => {
  //         window.removeEventListener("scroll", handleScroll)
  //     }

  // }, [])

  const refMenu = useRef();

  useOnClickOutside(refMenu, () => setIsToggled(false));

  return (
    <>
      <div
        className={`${
          hideHeader
            ? "hidden"
            : `flex z-50 ${
                !isHome
                  ? "bg-white border-b shadow-sm fixed"
                  : "absolute text-white"
              }   w-full p-4 justify-between items-center`
        }`}
      >
        <div ref={refMenu} className="relative">
          <div className="flex items-center gap-3">
            <button onClick={toggleMenu}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </button>
            {isHome && (
              <a href="/">
                <img src="./logo_white.png" className="w-32" />{" "}
              </a>
            )}
          </div>

          <div
            className={` ${
              isToggled ? "" : "hidden"
            } text-black absolute w-60 p-3 rounded-md bg-white border `}
          >
            {userInfo ? (
              <ul>
                <li>
                  <a href="/profile/info">Mon Profil</a>
                </li>
                <li>
                  <a href="/profile/mes-commandes">Mes Commandes</a>
                </li>
                <li>
                  <a href="/profile/changer-mot-de-passe">
                    Changer le mot de passe
                  </a>
                </li>

                <li className="pt-2">
                  <button onClick={logoutHandler}>Déconnexion</button>
                </li>
              </ul>
            ) : (
              <ul>
                <li>
                  <button
                    onClick={() => {
                      setIsOpen(true);
                      setIsToggled(false);
                    }}
                  >
                    Connexion
                  </button>
                </li>
                {/* <li>
                  <button
                    onClick={() => {
                      setIsOpen(true);
                      setIsToggled(false);
                    }}
                  >
                    Créer un compte
                  </button>
                </li> */}
              </ul>
            )}
          </div>
        </div>
        {!isHome && (
          <div className="flex items-center">
            <Logo isHome={isHome} />
          </div>
        )}

        <div className="pl-3 relative">
          <Link className="flex" to="/cart">
            <div>
              <svg
                className="w-8 h-8"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.3542 4.1665L10.8125 11.729M31.6458 4.1665L39.1875 11.729"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.1665 16.3542C4.1665 12.5 6.229 12.1875 8.7915 12.1875H41.2082C43.7707 12.1875 45.8332 12.5 45.8332 16.3542C45.8332 20.8333 43.7707 20.5208 41.2082 20.5208H8.7915C6.229 20.5208 4.1665 20.8333 4.1665 16.3542Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                />
                <path
                  d="M20.3332 29.1668V36.5627M29.9165 29.1668V36.5627M7.2915 20.8335L10.229 38.8335C10.8957 42.8752 12.4998 45.8335 18.4582 45.8335H31.0207C37.4998 45.8335 38.4582 43.0002 39.2082 39.0835L42.7082 20.8335"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </div>

            {/* <img src="/cart.png" className='w-8 h-8' alt="cart" srcSet="" /> */}
            <div className="absolute top-0 -right-2 text-white bg-red-600 rounded-full h-4 text-xs w-4 flex items-center justify-center ">
              {cartItems && cartItems.length && (
                <span className="">{cartItems.length}</span>
              )}
            </div>
          </Link>
        </div>
      </div>
      <LoginOrRegister isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}

export default Header;
