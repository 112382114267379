import React from "react";

function ContactUs() {
  const handleContactForm = (e) => {
    e.preventDefault();
  };
  return (
    <div className="min-h-screen max-w-screen-xl mx-auto py-20">
      <div className="p-2 relative w-full">
        <div className="absolute text-xl md:text-3xl text-center md:whitespace-nowrap font-bold text-white uppercase  top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">
          La magie des événements commence ici
        </div>
        <img src="/images/bg_contact_us.png" alt="" srcset="" />
      </div>

      <div className="py-4">
        <h1 className="text-3xl text-center">Contactez-nous</h1>
      </div>

      <div className="grid p-4 grid-cols-1 md:grid-cols-2 gap-3 ">
        <div>
          <form onSubmit={handleContactForm}>
            <div className="rounded-md border my-2">
              <input
                type="text"
                placeholder="Votre nom et prénom"
                className="p-3 w-full bg-transparent outline-none"
              />
            </div>
            <div className="rounded-md border my-2">
              <input
                type="text"
                placeholder="Votre adresse mail"
                className="p-3 w-full bg-transparent outline-none"
              />
            </div>

            <div className="rounded-md border my-2">
              <input
                type="text"
                placeholder="Votre numéro de téléphone"
                className="p-3 w-full bg-transparent outline-none"
              />
            </div>

            <div className="p-2 border rounded-md my-2">
              <textarea
                id=""
                placeholder="votre question !"
                className="p-2 w-full outline-none bg-transparent"
              ></textarea>
            </div>

            <div>
              <button className="p-3 text-white bg-primary rounded-md ">
                Contactez-nous
              </button>
            </div>
          </form>
        </div>
        <div className=" ">
          <div className="pt-3">
            <h1 className="text-2xl pb-3">Contactez-nous </h1>
            <p>info@aylink.ma</p>
          </div>
          <div className="pt-3">
            <h1 className="text-2xl pb-3">Appelez-nous</h1>
            <p>+212 700 708 708</p>
          </div>

          <div className="flex items-center gap-4 py-8">
            <a href="https://www.facebook.com/aylink.ma" target={"_blank"}>
              <svg
                aria-hidden="true"
                focusable="false"
                className="w-6 h-6"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path
                  fill="currentColor"
                  d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                ></path>
              </svg>
            </a>

            <a href="https://www.instagram.com/aylink.ma/" target={"_blank"}>
              <svg
                aria-hidden="true"
                focusable="false"
                data-icon="instagram"
                className="w-6 h-6"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
