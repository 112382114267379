import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileDetails, updateUser } from "../../actions/userActions";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { USER_UPDATE_PROFILE_RESET } from "../../constants/userConstants";
import ProfileLayout from "../../layout/ProfileLayout";

function MyProfileScreen({ location, history }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success } = userUpdateProfile;

  const submitHandler = (e) => {
    e.preventDefault();
    // console.log(user)
    const userData = {
      id: user.id,
      first_name: firstName,
      last_name: lastName,
      phone: phone,
      email: email,
    };
    dispatch(updateUser(userData));
  };

  // useEffect(() => {
  //     if (!userInfo) {
  //       history.push('/login')
  //     } else {
  //       if (!user || !user.name || success) {
  //         dispatch({ type: USER_UPDATE_PROFILE_RESET })
  //         // dispatch(getUserProfileDetails())
  //       } else {
  //         setName(user.name)
  //         setEmail(user.email)
  //       }
  //     }
  //   }, [dispatch, history, userInfo, user, success])

  useEffect(() => {
    if (!userInfo) {
      history.push("/");
    } else {
      if (!user || !user.email) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET });
        dispatch(getUserProfileDetails());
      } else {
        setLastName(user.last_name);
        setFirstName(user.first_name);
        setPhone(user.phone);
        setEmail(user.email);
      }
    }
  }, [dispatch, userInfo, user]);

  return (
    <ProfileLayout>
      <div>
        <h2 className="text-xl pb-3">Mon profil </h2>
        {message && <Message>{message}</Message>}
        {success && <Message>Profil mis à jour</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message>{error}</Message>
        ) : (
          <form className="" onSubmit={submitHandler}>
            <div className="  mb-3">
              <label
                for="first_name"
                class="block mb-2 text-sm font-medium text-gray-900 "
              >
                Prénom
              </label>
              <input
                value={firstName}
                disabled
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
                id="first_name"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="Prénom"
                required
              />
            </div>
            <div className=" mb-3">
              <label
                for="last_name"
                class="block mb-2 text-sm font-medium text-gray-900 "
              >
                Nom
              </label>
              <input
                value={lastName}
                disabled
                onChange={(e) => setLastName(e.target.value)}
                type="text"
                id="last_name"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="Nom"
                required
              />
            </div>

            <div className=" mb-3">
              <label
                for="phone"
                class="block mb-2 text-sm font-medium text-gray-900 "
              >
                Téléphone
              </label>
              <input
                value={phone}
                disabled
                onChange={(e) => setPhone(e.target.value)}
                type="text"
                id="phone"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="0600123344"
                required
              />
            </div>

            <div className=" mb-3">
              <label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 "
              >
                Email
              </label>
              <input
                value={email}
                disabled
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                id="email"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="test@gmail.com"
                required
              />
            </div>

            {/* <div className="mt-2 flex justify-end w-full">
              <button
                type="submit"
                className="bg-primary px-3 rounded-full text-white p-2 "
              >
                Enregstrer
              </button>
            </div> */}
          </form>
        )}
      </div>
    </ProfileLayout>
  );
}

export default MyProfileScreen;
