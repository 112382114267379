import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { listProducts } from "../actions/productActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Paginate from "../components/Paginate";
import Product from "../components/Product";

function ProductsListScreen({ match, history }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const pageNumber = match.params.pageNumber || 1;

  const keyword = location.search
    ? location.search.split("?")[1].split("=")[0]
    : "";
  const category = match.params.category || "";
  const type = match.params.type || "";

  const { loading, error, products, page, pages } = useSelector(
    (state) => state.productList
  );

  useEffect(() => {
    dispatch(listProducts(category, keyword, pageNumber));
  }, [dispatch, pageNumber, category, keyword]);

  return (
    <div className=" min-h-screen ">
      <div className="py-20 pt-32 xl:px-3 p-12 md:px-8 max-w-screen-xl mx-auto">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <>
            {products.length > 0 ? (
              <div className="grid md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-4 grid-cols-1 sm:grid-cols-2 lg:gap-x-6 gap-x-4 lg:gap-y-2 gap-y-1  lg:col-span-3">
                {products.map((product) => (
                  <div
                    className="group cursor-pointer fade-in text-sm lg:-20 duration-300"
                    key={product._id}
                  >
                    <Product product={product} />
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center  h-64 ">
                <img src="/icons/icon-camping.svg" alt="" srcSet="" />
                <div>Nothing Here Yet</div>
              </div>
            )}

            <Paginate pages={pages} page={page} keyword={keyword} type={type} />
          </>
        )}
      </div>
    </div>
  );
}

export default ProductsListScreen;
