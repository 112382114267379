import React from "react";
import uuid from "react-uuid";

// const items = [
//   {
//     id: 1,
//     title: "Facilité d'utilisation",
//     icon: "icons/easy-use.png",
//     description:
//       "Notre plateforme vous offre une expérience utilisateur fluide et intuitive.",
//   },
//   {
//     id: 1,
//     title: "Sécurité des paiements",
//     icon: "icons/secure-payment.png",
//     description:
//       "Nous offrons des méthodes de paiement sécurisées pour vous assurer une transaction sûre et fiable.",
//   },
//   {
//     id: 1,
//     title: "Support 24h/7j",
//     icon: "icons/support.png",
//     description: "+212 700 708 708 - info@aylink.ma",
//   },
// ];

const items = [
  {
    id: 1,
    title: "Facilité d'utilisation",
    icon: "icons/easy-use.png",
    description:
      "Découvrez une expérience utilisateur fluide et intuitive, avec une vue complète sur toutes les catégories et packs disponibles.",
  },
  {
    id: 1,
    title: "Transfert sécurisé des billets",
    icon: "icons/transfer-ticket-icn.png",
    description:
      "Transfert des billets facile tout en protégeant les deux parties.",
  },
  {
    id: 1,
    title: "Protection des billets",
    icon: "icons/protect-ticket-icn.png",
    description:
      "Évitez les fraudes et les ventes multiples grâce à notre système de billetterie sécurisé.",
  },
];

const Card = ({ item }) => {
  return (
    <div className="p-5 flex gap-3 text-center flex-col items-center shadow-md border rounded-xl">
      <img className="w-10" src={item.icon} alt={item.title} srcSet="" />
      <h1 className="text-xl font-semibold pt-3">{item.title}</h1>
      <p className="pt-2 text-sm opacity-80">{item.description}</p>
    </div>
  );
};

function WhyUs() {
  return (
    <div className="py-10">
      <div className="grid p-4 grid-cols-1 md:grid-cols-3 gap-4">
        {items.map((item) => (
          <Card key={uuid()} item={item} />
        ))}
      </div>
    </div>
  );
}

export default WhyUs;
