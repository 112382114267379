import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import CheckoutSteps from "../components/CheckoutSteps";
import { createOrder } from "../actions/orderActions";
import { ORDER_CREATE_RESET } from "../constants/orderConstants";
import { USER_DETAILS_RESET } from "../constants/userConstants";
import Meta from "../components/Meta";
import { baseURL, baseURLFiles } from "../axios";

const PlaceOrderScreen = ({ history }) => {
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  // console.log(cart)

  if (
    !cart.shippingAddress.phone &&
    !cart.shippingAddress.email &&
    !cart.shippingAddress.isUnder18 &&
    !cart.shippingAddress.isTermsChecked
  ) {
    history.push("/shipping");
  }

  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  cart.itemsPrice = addDecimals(
    cart.cartItems.reduce(
      (acc, item) => acc + parseFloat(item.price) * item.qty,
      0
    )
  );
  cart.shippingPrice = addDecimals(cart.itemsPrice > 100 ? 0 : 0);

  cart.taxPrice = addDecimals(Number((0.1 * cart.itemsPrice).toFixed(2)));

  cart.totalPrice = (
    Number(cart.itemsPrice) +
    Number(cart.shippingPrice) +
    Number(cart.taxPrice)
  ).toFixed(2);

  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, success, error } = orderCreate;

  useEffect(() => {
    if (success) {
      history.push(`/order/${order._id}`);
      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: ORDER_CREATE_RESET });
    }
    // eslint-disable-next-line
  }, [history, success]);

  const placeOrderHandler = () => {
    console.log(cart);

    // create pre payment order
    dispatch(
      createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        paymentMethod: "CMI",
        itemsPrice: cart.itemsPrice,
        shippingPrice: cart.shippingPrice,
        taxPrice: cart.taxPrice,
        totalPrice: cart.totalPrice,
      })
    );

    // CMI Payment Redirect
  };

  return (
    <div className="pt-32 container max-w-screen-lg mx-auto">
      <Meta title={"Place Your Order "} script={""} />
      <CheckoutSteps step2 step4 />
      <div className="md:flex">
        <div className="md:w-2/3 ">
          <div className="pr-1 mt-3">
            <div className="p-2 bg-white my-1">
              <h2 className="text-xl pb-2">Information</h2>
              <p>
                {/* <strong>Address:</strong> */}
                <p>
                  <strong>Name: </strong> {cart.shippingAddress.firstName}{" "}
                  {cart.shippingAddress.lastName}
                </p>
                <p>
                  <strong>Email: </strong>{" "}
                  <a href={`mailto:${cart.shippingAddress?.email}`}>
                    {cart.shippingAddress?.email}
                  </a>
                </p>
                <p>
                  <strong>Phone: </strong>{" "}
                  <a href={`tel:${cart.shippingAddress?.phone}`}>
                    {cart.shippingAddress?.phone}
                  </a>
                </p>
                {/* <p>
                <strong>CIN OR PASSPORT: </strong>{' '}
                {cart.shippingAddress?.passportCin}
              </p> */}
                {/* <p>
                <strong>ARRIVAL TIME: </strong>{' '}
                {cart.shippingAddress?.arrivalTime}
              </p> */}
              </p>
            </div>

            <div className="p-2 bg-white my-1">
              <h2 className="text-xl pb-2">Payment Method</h2>
              <div className="pt-2 flex items-center">
                <input type="radio" value={true} checked />
                <div className="px-4">
                  <img
                    className="w-40"
                    src="/images/logo-cmi-visa-mastercard-maroc-lcdmaroc.png"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
            </div>

            <div className="p-2 bg-white my-1">
              <h2 className="text-xl pb-2">Order Items</h2>
              {cart.cartItems.length === 0 ? (
                <Message>Your cart is empty</Message>
              ) : (
                <div>
                  {cart.cartItems.map((item, index) => (
                    <div key={index}>
                      <div className="flex py-2 items-center border-b justify-between">
                        <div className="flex ">
                          <img
                            src={
                              baseURL === "/api/"
                                ? item.image
                                : baseURLFiles + item.image
                            }
                            alt={item.name}
                            className="w-20 h-20"
                          />
                          <div className="pl-4">
                            <Link
                              className="text-md "
                              to={`/product/${item.product}`}
                            >
                              {item.name}
                            </Link>
                            <div className="text- opacity-90 ">
                              Price : {item.price} MAD ( {item.noPeople} )
                            </div>
                            <div className="text- opacity-90 ">
                              No of vegetarian : {item.noVegetarian}{" "}
                            </div>
                          </div>
                        </div>
                        <div></div>
                        <div>
                          {item.qty} x {item.price} MAD ={" "}
                          {item.qty * parseFloat(item.price)} MAD
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="md:w-1/3">
          <div className="pl-1 mt-3">
            <div className="bg-white p-2">
              <div>
                <h2 className="text-xl">Order Summary</h2>
              </div>
              <div className="flex justify-between py-2">
                <div>Items</div>
                <div>{cart.itemsPrice} MAD</div>
              </div>
              <div className="flex justify-between py-1">
                <div>Booking fees (10%)</div>
                <div>{cart.taxPrice} MAD</div>
              </div>
              {/* <div className="flex justify-between py-1">
                  <div>Addons</div>
                  <div>${cart.addonPrice}</div>
              </div> */}

              <div className="flex justify-between py-1">
                <div>Total</div>
                <div>{cart.totalPrice} MAD</div>
              </div>
              <div>{error && <Message variant="danger">{error}</Message>}</div>
              <div>
                <button
                  className="p-2 bg-primary text-white w-full"
                  type="button"
                  disabled={cart.cartItems === 0}
                  onClick={placeOrderHandler}
                >
                  Pay Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaceOrderScreen;
