import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../actions/userActions";
import Message from "./Message";
import Loader from "./Loader";

const validationSchema = yup.object().shape({
  first_name: yup.string().required("Please enter your first name"),
  last_name: yup.string().required("Please enter your last name"),
  phone: yup.string().required("Please enter your phone"),
  email: yup.string().email().required("Please verify your email"),
  password: yup.string().min(8).required("Please verify your password"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required(),
});

const Register = ({ setIsLogin }) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = async (data) => {
    let token = await window.grecaptcha.execute(
      "6LeIxVglAAAAAMnB1TZyonfAduP6olDH1jn2nRH2",
      { action: "submit" }
    );
    data.token = token;
    dispatch(registerUser(data));
  };

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, success } = userRegister;

  useEffect(() => {
    if (success && !error) {
      setIsLogin(false);
      setIsLogin(true);
    }
  }, [success, error]);

  return (
    <>
      {error && <Message variant={"danger"}>{error}</Message>}
      {loading && <Loader />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-3 pt-2">
          <div>
            <label>
              Prénom <span className="required"></span>
            </label>

            <div className="border rounded-full">
              <input
                className="p-3 w-full  outline-none bg-transparent "
                type="text"
                placeholder="Prénom"
                {...register("first_name")}
              />
            </div>
            {errors.first_name && (
              <p className="text-xs italic text-red-600 ">
                {errors.first_name.message}
              </p>
            )}
          </div>

          <div>
            <label>Nom</label>
            <div className="border rounded-full">
              <input
                className="p-3 w-full  outline-none bg-transparent "
                type="text"
                placeholder="Nom"
                {...register("last_name")}
              />
            </div>
            {errors.last_name && (
              <p className="text-xs italic text-red-600 ">
                {errors.last_name.message}
              </p>
            )}
          </div>
        </div>

        <div className="mt-2" controlId="email">
          <label>Adresse Email</label>
          <div className="border rounded-full">
            <input
              type="email"
              className="p-3 w-full  outline-none bg-transparent"
              placeholder="Adresse Email"
              {...register("email")}
            />
          </div>
          {errors.email && (
            <p className="text-xs italic text-red-600 ">
              {errors.email.message}
            </p>
          )}
        </div>

        <div className="mt-2" controlId="email">
          <label>Téléphone</label>
          <div className="border rounded-full">
            <input
              type="tel"
              className="p-3 w-full  outline-none bg-transparent"
              placeholder="Téléphone"
              {...register("phone")}
            />
          </div>
          {errors.phone && (
            <p className="text-xs italic text-red-600 ">
              {errors.phone.message}
            </p>
          )}
        </div>
        <div className="grid grid-cols-2 pt-2 gap-3">
          <div>
            <label>Mot de passe</label>
            <div className="border rounded-full">
              <input
                type="password"
                className="p-3 w-full  outline-none bg-transparent "
                placeholder="*******"
                {...register("password")}
              />
            </div>
            {errors.password && (
              <p className="text-xs italic text-red-600 ">
                {errors.password.message}
              </p>
            )}
          </div>

          <div>
            <label>Confirmer le mot de passe</label>
            <div className="border rounded-full">
              <input
                type="password"
                className="p-3 w-full  outline-none bg-transparent "
                placeholder="********"
                {...register("confirmPassword")}
              />
            </div>
            {errors.confirmPassword && (
              <p className="text-xs italic text-red-600 ">
                {errors.confirmPassword.message}
              </p>
            )}
          </div>
        </div>
        <div className="pt-3 flex items-center justify-center">
          {/* <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={onCaptchaChange}
          /> */}
        </div>

        <div className="pt-3 flex items-center justify-center">
          <button
            // disabled={!token}
            className="p-3  px-6 mt-2 bg-primary rounded-full text-gray-50"
            type="submit"
          >
            S’inscrire
          </button>
        </div>
      </form>

      <div className="py-3 text-center">
        <div>
          Vous avez un compte ?{" "}
          <button className="text-secondary" onClick={() => setIsLogin(true)}>
            Connexion
          </button>
        </div>
      </div>
    </>
  );
};

export default Register;
