import React from "react";

const Footer = () => {
  return (
    <div id="footer" className="relative">
      <div className="absolute md:flex items-center w-9/12  md:w-7/12 gap-x-2 left-1/2  p-4 -translate-x-1/2 -top-10 rounded-md bg-secondary">
        <h1 className=" text-white md:whitespace-nowrap">
          Inscrivez-vous à la Newsletter
        </h1>
        <form action="#" className="pr-3 w-full pt-2">
          <div className="w-full flex items-center ">
            <div className="bg-white rounded-l-md w-full ">
              <input
                type="text"
                placeholder="Adresse Email "
                className="p-2 bg-transparent outline-none w-full"
              />
            </div>
            <button className="p-2 bg-primary text-white px-4 rounded-r-md">
              S’inscrire
            </button>
          </div>
        </form>
      </div>
      <footer className="pt-16  bg-primary text-white">
        <div className="grid grid-cols-1 md:grid-cols-3 p-4 md:p-3">
          <div className="">
            <img className="w-32" src="/logo_white.png" alt="" />
            <p className="text-sm pt-2">
              Aylink est une plateforme de billetterie de nouvelle génération
              qui vous permet d'acheter des billets en ligne pour les événements
              de votre choix. Sa mission est de vous proposer la gamme la plus
              étendue de concerts, événements sportifs, spectacles, séances de
              cinéma, festivals, pièces de théâtre, et spectacles d'humour, en
              plus de vous offrir des options sécurisées pour transferer vos
              tickets avec vos amis.
            </p>
          </div>
          <div></div>
          <div className="">
            <div className="pb-2 text-xl font-bold">À PROPOS</div>
            <div className="py-2">
              <a href="/pages/mentions-legales">Qui sommes nous ?</a>
            </div>
            <div className="py-2">
              <a href="/pages/contacter-nous">Contactez-nous</a>
            </div>
            <div className="py-2">
              <a href="/pages/mentions-legales">Mentions légales</a>
            </div>
          </div>

          <div></div>
        </div>

        {/* copyright */}
        <div className="flex items-center justify-center gap-x-8">
          <div className="flex items-center gap-4 py-4">
            <a href="https://www.facebook.com/aylink.ma" target={"_blank"}>
              <svg
                aria-hidden="true"
                focusable="false"
                className="w-6 h-6"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path
                  fill="currentColor"
                  d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                ></path>
              </svg>
            </a>

            <a href="https://www.instagram.com/aylink.ma/" target={"_blank"}>
              <svg
                aria-hidden="true"
                focusable="false"
                data-icon="instagram"
                className="w-6 h-6"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                ></path>
              </svg>
            </a>
          </div>
          <div className="py-4 text-center">
            2023 Aylink, Powered by Aynnaka
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
