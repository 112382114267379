import React, { useEffect } from "react";
import PaymentForm from "../components/PaymentForm";
import { useState } from "react";
import axiosInstance from "../axios";

function DonateScreen() {
  const [phone, setPhone] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState(0);

  const [isNotValid, setIsNotValid] = useState(true);

  const handleCreateDonation = (e) => {
    e.preventDefault();

    // alert("ok");
    axiosInstance
      .post("/donation/create", {
        phoneDon: phone,
        emailDon: email,
        totalDon: amount,
        fullName: fullName,
      })
      .then((res) => {
        // console.log();
        document.location.href = "/donate/confirm?id=" + res.data._id;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="pt-32 min-h-screen  relative">
      <div className="max-w-screen-sm mx-auto p-4">
        <h1 className="font-bold text-xl ">AYLINK Donation</h1>
        <img
          src="https://epiceventsstatic.s3.amazonaws.com/images/Organizer/avatar/Profil.png"
          className="w-20 h-20 mx-auto rounded-full"
          alt=""
        />
        <h1 className="text-center pt-2 font-semibold">BMS Gala Dinner</h1>
        <form onSubmit={handleCreateDonation}>
          <div className="my-3">
            <label className="opacity-70 ">Full Name</label>
            <div className="border rounded-md mt-1">
              <input
                className="p-2 w-full bg-transparent outline-none  "
                type="text"
                placeholder="Enter name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="my-3">
            <label className="opacity-70 ">Email</label>
            <div className="border rounded-md mt-1">
              <input
                className="p-2 w-full bg-transparent outline-none  "
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="my-3">
            <label className="opacity-70 ">Phone Number</label>
            <div className="border rounded-md mt-1">
              <input
                className="p-2 w-full bg-transparent outline-none  "
                type="tel"
                placeholder="Enter Phone"
                required
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>
          <div className="my-3">
            <label className="opacity-70 ">Amount</label>
            <div className="border flex items-center justify-center rounded-md mt-1">
              <input
                className="p-2 w-full bg-transparent outline-none  "
                type="text"
                placeholder="Enter amount"
                required
                value={amount}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => setAmount(e.target.value)}
              />
              <span className="px-2"> MAD</span>
            </div>
          </div>
          <button className="hover:scale-[1.01] disabled:scale-[1] disabled:bg-opacity-70 hover:bg-opacity-90 w-full rounded-full p-3 bg-primary text-white">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default DonateScreen;
