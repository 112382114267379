import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import uuid from "react-uuid";
import { listOrganizerBySlugDetails } from "../actions/organizerActions";
import { listProductsByOrganizer } from "../actions/productActions";
import { listSectionByOrganizer } from "../actions/sectionActions";
import Loader from "../components/Loader";
import Paginate from "../components/Paginate";
import Product from "../components/Product";
import ReactPixel from "react-facebook-pixel";
import { baseURL, baseURLFiles } from "../axios";

const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

const SectionItem = ({ section, selectedSection }) => {
  return (
    <div className={` ${selectedSection === section._id ? "text-red" : ""}`}>
      <Link to={`?section=${section._id}`}>
        <div className="flex justify-center items-center">
          <img
            src={
              baseURL === "/api/" ? section.image : baseURLFiles + section.image
            }
            className="object-cover w-20 h-20  rounded-full bg-white"
            alt=""
            srcSet=""
          />
        </div>
        <div className="text-sm capitalize pt-2 text-center">
          {section.name}
        </div>
      </Link>
    </div>
  );
};
const SectionItemPlaceholder = () => {
  return (
    <div className=" ">
      <div className="object-cover w-16 h-16 rounded-full bg-gray-100"></div>

      <div className="h-4 bg-gray-100 w-20"></div>
    </div>
  );
};

const ProductPlaceholder = () => {
  return (
    <div>
      <div className="w-full fade-in lg:group-hover:scale-105 group-hover:shadow-me duration-300 rounded-xl  aspect-video overflow-hidden  shadow-me">
        <div className="duration-300 w-full h-full object-top object-cover bg-gray-100"></div>
      </div>

      <div className="flex justify-start py-2">
        <div className=" ">
          <p className="opacity-70 uppercase font-mono tracking-widest text-sm md:text-base h-2 bg-gray-100 "></p>
          <h3 className="font-600 pb-2 mt-1 text-base md:text-xl tracking-wider uppercase h-2 bg-gray-100"></h3>
        </div>
      </div>
    </div>
  );
};

function OrganizationScreen({ match, location }) {
  const { organization } = useParams();
  const pageNumber = match.params.pageNumber || 1;
  const section =
    location.search && location.search.includes("section")
      ? parseInt(location.search.split("?")[1].split("=")[1])
      : "";

  console.log(location.search);
  const { loading, error, organizer } = useSelector(
    (state) => state.organizerDetails
  );
  const productList = useSelector((state) => state.productList);
  const { products, page, pages } = productList;

  const sectionList = useSelector((state) => state.sectionList);
  const {
    loading: loadingSections,
    error: errorSections,
    sections,
  } = sectionList;

  const dispatch = useDispatch();
  console.log(pageNumber);
  useEffect(() => {
    dispatch(listProductsByOrganizer(organization, section, pageNumber));
  }, [dispatch, organization, section, pageNumber]);

  useEffect(() => {
    dispatch(listOrganizerBySlugDetails(organization));
    dispatch(listSectionByOrganizer(organization));
  }, [organization]);

  useEffect(() => {
    if (products && products.length == 1 && section == "") {
      document.location.href = `/p/${organization}/${products[0].slug}/`;
    }
  }, [products]);

  // useEffect(() => {
  //   if (organization == "bms-gala-dinner") document.location.href = "/donate";
  // }, []);

  useEffect(() => {
    ReactPixel.init("1137663290409027", advancedMatching, options);
    ReactPixel.pageView();
  }, []);

  return loading ? (
    <Loader />
  ) : error ? (
    error === "Pas trouvé." ? (
      <div className="pt-24 pb-20 xl:px-0 px-4 max-w-screen-xl mx-auto flex flex-col justify-center">
        <img
          src="/mo-results-found.png"
          className=" w-60 mt-10 mx-auto mb-10"
          alt="No found results"
        />
        <p className="text-center">
          Cet événement a expiré ou n'a pas été trouvé
        </p>
      </div>
    ) : (
      <div className="pt-24 pb-20 xl:px-0 px-4 max-w-screen-xl mx-auto">
        {error}
      </div>
    )
  ) : organizer.isEnded ? (
    <div className="pt-24 pb-20 xl:px-0 px-4 max-w-screen-xl mx-auto flex flex-col justify-center">
      <img
        src="/mo-results-found.png"
        className=" w-60 mt-10 mx-auto mb-10"
        alt="No found results"
      />
      <p className="text-center">
        Cet événement a expiré ou n'a pas été trouvé
      </p>
    </div>
  ) : (
    <div className="pt-24 pb-20 xl:px-0 px-4 max-w-screen-xl mx-auto">
      <div
        className="bg-cover relative min-h-[20vh] md:min-h-[30vh] bg-center w-full  rounded-xl"
        style={{ backgroundImage: `url(${organizer.cover})` }}
      >
        <div className=" absolute -bottom-10 text-center left-1/2 -translate-x-1/2 bg-black rounded-full">
          <img
            src={
              baseURL === "/api/"
                ? organizer.avatar
                : baseURLFiles + organizer.avatar
            }
            className="object-cover p-0.5 w-20 h-20 md:w-24 md:h-24 rounded-full"
            alt=""
          />
        </div>
      </div>

      <div className="flex flex-col pt-10 justify-center items-center">
        <h1 className="text-xl py-3 font-bold">{organizer.name}</h1>
        <p className="max-w-3xl text-center text-sm mx-auto whitespace-pre-line">
          {organizer.about}
        </p>
      </div>

      {/* <h1 className='text-xl font-bold '> {organizer?.about} </h1> */}
      <div className="flex justify-center flex-wrap py-4 gap-x-6">
        {loadingSections
          ? [...new Array(3)].map((item) => (
              <SectionItemPlaceholder key={uuid()} />
            ))
          : errorSections
          ? errorSections
          : sections.map((sec) => (
              <div className="">
                <SectionItem
                  key={uuid()}
                  selectedSection={section}
                  section={sec}
                />
              </div>
            ))}
      </div>
      <div className="max-w-screen-lg mx-auto py-4 px-4">
        <div className="grid grid-cols-1 min-h-[60vh] md:grid-cols-2 lg:grid-cols-3 gap-3">
          {loading
            ? [...new Array(3)].map((item) => (
                <ProductPlaceholder key={uuid()} />
              ))
            : error
            ? error
            : products.map((product) => (
                <Product
                  organizer={organizer.slug}
                  key={uuid()}
                  product={product}
                />
              ))}
        </div>
        <div>
          <Paginate pages={pages} organizer={organizer.slug} page={page} />
        </div>
      </div>
    </div>
  );
}

export default OrganizationScreen;
