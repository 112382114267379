import React, { useEffect } from "react";
import Meta from "../components/Meta";

function SucessPayment() {
  useEffect(() => {
    localStorage.removeItem("ay_cartItems");
  }, []);

  return (
    <div className="pt-32  min-h-screen w-full flex flex-col justify-between items-center ">
      <Meta title={"Success payment"} />
      <div className="pt-20 flex flex-col items-center text-center">
        <div className="text-green-600 ">
          <img
            className="w-60"
            src="/images/payment_sucess.png"
            alt=""
            srcset=""
          />
        </div>
        <div className="py-6">
          <h1>Votre paiement a été effectué avec succès</h1>
        </div>

        <a href="/">Retour</a>
      </div>
    </div>
  );
}

export default SucessPayment;
